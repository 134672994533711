import httpClient  from '../api'; 
const API_CONTROLLER = 'setting-group/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                isSettingCustomer: search.isSettingCustomer,
                title: search.title
            }
        });
    },

    getSettingGroups(search) { 
        let url = API_CONTROLLER + 'get-settinggroups';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                setting_title: search.setting_title
            }
        });
    },
}